var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-4"},[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("SERVE Supervisor Portal")]),_c('v-spacer'),_c('v-dialog',{attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_vm._v("Add Bulk Hours")])]}}]),model:{value:(_vm.bulkHoursDialog),callback:function ($$v) {_vm.bulkHoursDialog=$$v},expression:"bulkHoursDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Add Bulk Hours")]),_c('v-card-text',[_c('p',[_vm._v("This will allow you to search for and select multiple students to whom you wish to add hours.")]),_c('v-text-field',{attrs:{"label":"Description/Event","outlined":""},model:{value:(_vm.bulkAdd.desc),callback:function ($$v) {_vm.$set(_vm.bulkAdd, "desc", $$v)},expression:"bulkAdd.desc"}}),_c('v-row',[_c('v-col',[_c('date-picker',{attrs:{"label":"Date","outlined":""},model:{value:(_vm.bulkAdd.date),callback:function ($$v) {_vm.$set(_vm.bulkAdd, "date", $$v)},expression:"bulkAdd.date"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Hours","type":"number","min":"0","max":"10","step":"1","outlined":""},model:{value:(_vm.bulkAdd.hours),callback:function ($$v) {_vm.$set(_vm.bulkAdd, "hours", $$v)},expression:"bulkAdd.hours"}})],1)],1),_c('v-autocomplete',{attrs:{"items":_vm.notAddedStudents,"label":"Student Search","item-text":"name","return-object":"","outlined":""},on:{"change":_vm.addStudentToList},model:{value:(_vm.personSearch),callback:function ($$v) {_vm.personSearch=$$v},expression:"personSearch"}}),_c('v-list',{staticStyle:{"max-height":"500px","overflow":"auto"}},_vm._l((_vm.bulkAddStudents),function(ref){
var pidm = ref.pidm;
var name = ref.name;
return _c('v-list-item',{key:pidm},[_c('v-list-item-title',[_vm._v(_vm._s(name))]),_c('v-list-item-action',[_c('v-btn',{attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.removeStudent(pidm)}}},[_c('v-icon',[_vm._v("fal fa-trash-alt")])],1)],1)],1)}),1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.bulkHoursDialog = false}}},[_vm._v("Close")]),_c('v-btn',{attrs:{"text":"","color":"success"},on:{"click":function($event){return _vm.addBulkHours()}}},[_vm._v("Add Hours")])],1)],1)],1)],1),_c('v-card-text',[_c('p',[_vm._v("This page will house the students that are assigned to the current supervisor, and allow that supervisor to enter hours worked by any of the students in their list.")])]),_c('v-data-table',{attrs:{"items":_vm.students,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.lastEntry",fn:function(ref){
var item = ref.item;
return [(item.assignments && item.assignments.history && item.assignments.history.length > 0)?_c('span',[_vm._v(_vm._s(_vm.stringFormatDate(item.assignments.history[item.assignments.history.length - 1].date, true, true)))]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openAddHoursDialog(item)}}},[_c('v-icon',[_vm._v("fal fa-plus-circle")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openDetailDialog(item)}}},[_c('v-icon',[_vm._v("fal fa-eye")])],1)]}}])}),_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.addHoursDialog),callback:function ($$v) {_vm.addHoursDialog=$$v},expression:"addHoursDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Add Hours for "+_vm._s(_vm.selectedName))]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Hours","type":"number","min":"0","outlined":""},model:{value:(_vm.add.hours),callback:function ($$v) {_vm.$set(_vm.add, "hours", $$v)},expression:"add.hours"}}),_c('date-picker',{attrs:{"label":"Date","outlined":""},model:{value:(_vm.add.date),callback:function ($$v) {_vm.$set(_vm.add, "date", $$v)},expression:"add.date"}}),_c('v-text-field',{attrs:{"label":"Description (Optional)","outlined":""},model:{value:(_vm.add.desc),callback:function ($$v) {_vm.$set(_vm.add, "desc", $$v)},expression:"add.desc"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.addHoursDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"success","text":""},on:{"click":function($event){return _vm.addHours()}}},[_vm._v("Add Hours")])],1)],1)],1),_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.detailDialog),callback:function ($$v) {_vm.detailDialog=$$v},expression:"detailDialog"}},[_c('serve-detail',{attrs:{"id":_vm.selectedId,"is-dialog":""},on:{"close":function($event){_vm.detailDialog = false}}},[_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.detailDialog = false}}},[_vm._v("Close")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }