<template>
  <v-card class="ma-4">
    <v-toolbar>
      <v-toolbar-title>SERVE Supervisor Portal</v-toolbar-title>
      <v-spacer />
      <v-dialog v-model="bulkHoursDialog" width="600">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text>Add Bulk Hours</v-btn>
        </template>
        <v-card>
          <v-card-title>Add Bulk Hours</v-card-title>
          <v-card-text>
            <p>This will allow you to search for and select multiple students to whom you wish to add hours.</p>
            <v-text-field v-model="bulkAdd.desc" label="Description/Event" outlined />
            <v-row>
              <v-col>
                <date-picker v-model="bulkAdd.date" label="Date" outlined />
              </v-col>
              <v-col>
                <v-text-field v-model="bulkAdd.hours" label="Hours" type="number" min="0" max="10" step="1" outlined />
              </v-col>
            </v-row>
            <v-autocomplete v-model="personSearch" :items="notAddedStudents" label="Student Search" item-text="name" return-object outlined @change="addStudentToList" />
            <v-list style="max-height:500px;overflow:auto">
              <v-list-item v-for="{ pidm, name } in bulkAddStudents" :key="pidm">
                <v-list-item-title>{{ name }}</v-list-item-title>
                <v-list-item-action>
                  <v-btn color="error" icon @click="removeStudent(pidm)">
                    <v-icon>fal fa-trash-alt</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="bulkHoursDialog = false">Close</v-btn>
            <v-btn text color="success" @click="addBulkHours()">Add Hours</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-card-text>
      <p>This page will house the students that are assigned to the current supervisor, and allow that supervisor to enter hours worked by any of the students in their list.</p>
    </v-card-text>
    <v-data-table :items="students" :headers="headers">
      <template v-slot:item.lastEntry="{ item }">
        <span v-if="item.assignments && item.assignments.history && item.assignments.history.length > 0">{{ stringFormatDate(item.assignments.history[item.assignments.history.length - 1].date, true, true) }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn icon @click="openAddHoursDialog(item)">
          <v-icon>fal fa-plus-circle</v-icon>
        </v-btn>
        <v-btn icon @click="openDetailDialog(item)">
          <v-icon>fal fa-eye</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="addHoursDialog" width="400">
      <v-card>
        <v-card-title>Add Hours for {{ selectedName }}</v-card-title>
        <v-card-text>
          <v-text-field v-model="add.hours" label="Hours" type="number" min="0" outlined />
          <date-picker v-model="add.date" label="Date" outlined />
          <v-text-field v-model="add.desc" label="Description (Optional)" outlined />
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="addHoursDialog = false">Cancel</v-btn>
          <v-btn color="success" text @click="addHours()">Add Hours</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="detailDialog" width="600">
      <serve-detail :id="selectedId" is-dialog @close="detailDialog = false">
        <v-card-actions>
          <v-btn text @click="detailDialog = false">Close</v-btn>
        </v-card-actions>
      </serve-detail>
    </v-dialog>
  </v-card>
</template>
<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import { stringFormatDate } from '@/helpers/formatters'
export default {
  components: {
    DatePicker: () => import('@/components/forms/inputs/DatePicker'),
    ServeDetail: () => import('@/components/serve/detail')
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const isAdmin = computed(() => user.value.roles.includes('Technology Services') || user.value.roles.includes('Student Development'))
    const stuServ = root.$feathers.service('serve/student')
    const areaServ = root.$feathers.service('serve/area')
    const term = ref('')
    const terms = ref([])
    const areas = ref([])
    const students = ref([])
    const headers = computed(() => {
      const arr = [
        { text: 'Banner ID', value: 'bannerId' },
        { text: 'Last Name', value: 'last' },
        { text: 'First Name', value: 'first' },
        { text: 'Hours', value: 'assignments.hours' },
        { text: 'Last Entry', value: 'lastEntry' },
        { text: 'Add Hours', value: 'actions' }
      ]
      if (areas.value.length > 1) {
        arr.splice(3, 0, { text: 'Assignment', value: 'assignments.assignment' })
      }
      return arr
    })
    const isLoaded = ref(false)

    onMounted(async () => {
      const dt = new Date()
      dt.setMonth(dt.getMonth() + 2)
      root.$feathers.service('system/term').find({ query: { term: { $gte: '202360', $regex: '(05|60)$' }, start: { $lte: dt }, $select: ['term', 'label'], $sort: { start: -1 } } }).then(({ data }) => {
        terms.value = data.map(({ term, label }) => { return { text: label, value: term } })
        term.value = data[0].term
      })

      // Make sure the current user either is an admin or has permission to one of the areas
      if (isAdmin.value) {
        const { data } = await areaServ.find()
        areas.value = data.map(({ area }) => area)
      } else {
        const { data } = await areaServ.find({ query: { 'supervisors.pidm': user.value.pidm } })
        if (data.length === 0) {
          root.$store.dispatch('main/snackbar', { color: 'error', text: 'You are not set up with permission to enter any hours for SERVE' })
          root.$router.push('/')
        } else {
          areas.value = data.map(({ area }) => area)
        }
      }
      isLoaded.value = true
      loadStudents()
    })

    async function loadStudents () {
      if (isLoaded.value && areas.value.length > 0) {
        const aggregate = [
          { $unwind: '$assignments' },
          { $match: { 'assignments.term': term.value, 'assignments.assignment': { $in: areas.value } } },
          { $sort: { last: 1, first: 1 } }
        ]
        const { data } = await stuServ.find({ query: { aggregate } })
        students.value = data.map((rec) => {
          // if ('history' in rec.assignments && rec.assignments.history.length > 0) {
          //   rec.history = rec.assignments.history.reverse()
          // }
          if (!('hours' in rec.assignments)) {
            rec.assignments.hours = 0
          }
          return rec
        })
      }
    }

    const addHoursDialog = ref(false)
    const add = ref({
      hours: 0,
      date: new Date().toISOString().substring(0, 10),
      desc: ''
    })
    const selectedId = ref('')
    const selectedName = ref('')
    function openAddHoursDialog ({ _id, last, first }) {
      selectedId.value = _id
      selectedName.value = first + ' ' + last
      addHoursDialog.value = true
    }

    async function addHours () {
      const historyRec = { ...add.value, addedBy: user.value.name }
      const data = await stuServ.patch(selectedId.value, { $push: { 'assignments.$[el].history': historyRec }, $inc: { 'assignments.$[el].hours': parseInt(add.value.hours) } }, { query: { arrayFilters: [{ 'el.term': term.value }] } })
      data.assignments = data.assignments.find(({ term: t }) => t === term.value)
      const index = students.value.find(({ _id }) => _id === selectedId.value)
      students.value.splice(index, 1, data)
      addHoursDialog.value = false
    }

    const detailDialog = ref(false)
    function openDetailDialog ({ _id }) {
      selectedId.value = _id
      detailDialog.value = true
    }

    const bulkHoursDialog = ref(false)
    const bulkAdd = ref({
      date: new Date().toISOString().substring(0, 10),
      desc: '',
      hours: 0
    })
    const personSearch = ref(null)
    const bulkAddStudents = ref([])
    const notAddedStudents = computed(() => students.value.filter(({ pidm }) => !bulkAddStudents.value.map(({ pidm }) => pidm).includes(pidm)).map(({ pidm, last, first }) => { return { name: first + ' ' + last, pidm } }))
    function addStudentToList (val) {
      bulkAddStudents.value.push(val)
      root.$nextTick(() => { personSearch.value = null })
    }
    function removeStudent (pidm) {
      const index = bulkAddStudents.value.findIndex(({ pidm: p }) => p === pidm)
      if (index >= 0) {
        bulkAddStudents.value.splice(index, 1)
      }
    }
    function addBulkHours () {
      bulkHoursDialog.value = false
    }

    return {
      user,
      isAdmin,
      term,
      terms,
      areas,
      students,
      headers,
      loadStudents,
      addHoursDialog,
      add,
      selectedId,
      selectedName,
      openAddHoursDialog,
      addHours,
      detailDialog,
      openDetailDialog,
      bulkHoursDialog,
      bulkAdd,
      bulkAddStudents,
      personSearch,
      notAddedStudents,
      addStudentToList,
      removeStudent,
      addBulkHours,
      stringFormatDate
    }
  }
}
</script>
